import { Injectable } from '@angular/core';

// 96x96     | area_select_icon
// 480×480   | large
// 1920x1080 | login_page_background
// 220×220   | medium
// 256x256   | navigation_icon
// 1440x1080 | room_background_image
// 960x540   | stage_image
// 100×100   | thumbnail
// 136x136   | user_profile_picture
// 48x48     | user_profile_picture_icon
// 1090x     | wide

type ImageStyleString = "area_select_icon" | "large" | "login_page_background" |
                        "medium" | "navigation_icon" | "room_background_image" |
                        "stage_image" | "thumbnail" | "user_profile_picture" |
                        "user_profile_picture_icon" | "wide"

@Injectable({ providedIn: 'root' })
export class FilePathService
{
  // https://<HOSTNAME>/sites/default/files/styles/<STYLENAME>/public/users/<IMAGENAME>.png
  // https://<HOSTNAME>/files/styles/<STYLENAME>/public/users/<IMAGENAME>.png
  protected styleRegex = /(.+\/styles)\/[^\/]+\/(.+)$/;

  // https://<HOSTNAME>/sites/default/files/users/<IMAGENAME>.png
  // https://<HOSTNAME>/files/users/<IMAGENAME>.png
  protected pathRegex = /(.+\/files)\/(.+)$/;

  protected imageStylePath(style: ImageStyleString, path?: string)
  {
    if (!path) {
      return null;
    }
    // Already image styled?
    if (this.styleRegex.test(path)) {
      return path.replace(this.styleRegex, '$1/'+ style +'/$2');
    }
    // Else default files?
    if (this.pathRegex.test(path)) {
      return path.replace(this.pathRegex, '$1/styles/'+ style +'/public/$2');
    }
    // Return original url?
    return path;
  }

  /** Returns path using "user_profile_picture_icon" image style (48x48) */
  userProfileIcon(path?: string)
  {
    return this.imageStylePath('user_profile_picture_icon', path);
  }

  /** Returns path using "user_profile_picture" image style (136x136) */
  userProfilePicture(path?: string)
  {
    return this.imageStylePath('user_profile_picture', path);
  }
}
