<div class="template__login">
  <div class="bgSign" [style.background-image]="'url(' + backgroundPath + ')'">
<!--    render the form and logo with the same 'ngIf' to minimise layout shift-->
    <form *ngIf="renderLogo" name="loginForm" #f="ngForm" (ngSubmit)="onSubmit(f)">
      <div class="loginInnerBox">
        <div class="gathoLogo">
          <img [src]="logoPath" alt="gatho-logo" title="Gatho"/>
        </div>
        <div *ngIf="passwordNeedsReset">
          <a variant="info" (click)="openGetPassword()" class="is-light">
            Your password needs to be reset. Reset it Here.</a
          >
        </div>

        <ng-container *ngIf="!loading; else spinner">
          <div class="mat-error" *ngIf="errorMessage">{{ errorMessage }}</div>
          <mat-form-field class="has-bgDark field-email" appearance="fill">
            <mat-label>EMAIL</mat-label>
            <input
              matInput
              type="email"
              name="email"
              autocomplete="email"
              placeholder="EMAIL"
              [ngModel]="payload.email"
              #email="ngModel"
              (blur)="checkEmailIsFromImportedUser(f)"
              required
              email
              tabindex="1"
              autofocus
            />
            <mat-error *ngIf="email.touched && email.invalid" class="mat-error"
              >Please enter your Email Address</mat-error
            >
          </mat-form-field>
          <mat-form-field class="has-bgDark field-password" appearance="fill">
            <mat-label>PASSWORD</mat-label>
            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              name="password"
              autocomplete="password"
              placeholder="PASSWORD"
              [ngModel]="payload.password"
              #password="ngModel"
              required
              tabindex="2"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
              type="button"
            >
              <mat-icon [title]="hide ? 'Show password' : 'Hide password'">{{
                hide ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </mat-form-field>
          <button type="submit" class="is-primary" mat-flat-button>
            SIGN IN
          </button>
        </ng-container>
        <ng-template #spinner>
          <div class="center">
            <mat-spinner></mat-spinner>
            <p class="text-center">Loading...</p>
          </div>
        </ng-template>
        <div class="forgotLink">
          <a variant="info" (click)="openGetPassword()" class="is-light"
            >Forgot your password?</a
          >
        </div>
        <div class="btnSignup">
          Don’t have an account?
          <a (click)="navigateToSignUp()" class="is-light">SIGN UP</a>
        </div>
      </div>
    </form>
  </div>
</div>
