import {Component, Input, OnInit} from '@angular/core';
import { ApiService } from "@gatho/services/api/api.service";
import { FrontPageArea } from "@shared/models/FrontPageArea";

@Component({
  selector: 'app-logo-section',
  templateUrl: './logo-section.component.html',
  styleUrls: ['./logo-section.component.scss']
})
export class LogoSectionComponent implements OnInit {

  @Input() title!: string;

  featuredAreas: FrontPageArea[] = [];

  constructor(
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    this.loadFeaturedAreas();
  }

  async loadFeaturedAreas() {
    const response = await this.apiService.getFrontPageContent();
    this.featuredAreas = response as FrontPageArea[];
  }

}
