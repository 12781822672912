import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/pages/login/login.component';
import { LogoutComponent } from './components/pages/logout/logout.component';
import { RouteListComponent } from './route-list/route-list.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
// import { VerifyAgeComponent } from './components/pages/verify-age/verify-age.component';   //GATHO-495
import { AuthGuardService as AuthGuard } from './services/guards/auth-guard.service';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { OtaMobileComponent } from './components/pages/sign-up/ota-mobile/ota-mobile.component';
import { PageNotFoundComponent } from './components/pages/page-not-found/page-not-found.component';
import { DisclaimerPagesComponent } from './components/disclaimers/pages/disclaimer-pages/disclaimer-pages.component';
import { BecomeGathoVenueComponent } from "@gatho/components/pages/become-gatho-venue/become-gatho-venue.component";
import { MainLandingComponent } from "@gatho/components/pages/main-landing/main-landing.component";

const routes: Routes = [
  {
    title: "Gatho - See you there, from anywhere",
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/lobby/lobby.module').then((m) => m.LobbyModule),
  },
  // { path: 'verify-age', component: VerifyAgeComponent },  //GATHO-495
  { path: 'gatho', component: MainLandingComponent },
  { path: 'login', component: LoginComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'password-reset', component: ResetPasswordComponent },
  { path: 'sign-up/ota', component: OtaMobileComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'tc', component: DisclaimerPagesComponent },
  { path: 'privacy', component: DisclaimerPagesComponent },
  { path: 'returns', component: DisclaimerPagesComponent },
  { path: 'rules', component: DisclaimerPagesComponent },
  { path: 'route-list', component: RouteListComponent },
  { path: 'become-gatho-venue', component: BecomeGathoVenueComponent },
  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
