<div class="template__signUp">
  <div class="bgSign">
    <div class="signupOuterBox gradientTheme hasScrollbarLight">
      <div class="disclaimer">
        <div class="disclaimer__heading">
          <h3 *ngIf="isTc" class="disclaimer__title text-center">
            Terms & Conditions
          </h3>
          <h3 *ngIf="isPrivacy" class="disclaimer__title text-center">
            Privacy Policy
          </h3>
          <h3 *ngIf="isReturns" class="disclaimer__title text-center">
            Returns Policy
          </h3>
          <h3 *ngIf="isRulesForContrib" class="disclaimer__title text-center">
            Rules for contributors
          </h3>
        </div>

        <div class="disclaimer__content">
          <ng-container *ngIf="!loading; else spinner">
            <div *ngIf="isTc" innerHTML="{{ contentTc }}"></div>
            <div *ngIf="isPrivacy" innerHTML="{{ contentPrivacy }}"></div>
            <div *ngIf="isReturns" innerHTML="{{ contentReturns }}"></div>
            <div
              *ngIf="isRulesForContrib"
              innerHTML="{{ contentRulesForContrib }}"
            ></div>
          </ng-container>
          <ng-template #spinner>
            <div class="center">
              <mat-spinner></mat-spinner>
              <p class="text-center">Loading...</p>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
