<marketing-nav></marketing-nav>
<div class="template__signUp">
  <div class="bgSign">
    <div class="signupOuterBox gradientTheme hasScrollbarLight">
      <div class="signupOuterContent">
<!--        <h6 class="is-light">Return to login</h6>-->
        <div class="form-container">
          <h4>Host a Gatho Event and start maximising your live entertainment now. Complete the form below to register your interest. We'll contact you once your application progresses.</h4>
          <br/>
          <iframe
            frameborder="0"
            style="height:100%;width:100%;border:none;"
            src='https://forms.zohopublic.com.au/zoho19/form/BecomeaGathoVenue/formperma/Sck61152BnR4YkjOxfR4GZ2Ub81thKNLjXly34NslX0'>
          </iframe>
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</div>



