import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';



@Injectable({
  providedIn: 'root'
})
export class AppEffects {
  constructor(private actions$: Actions) {}

  getEventSource(url: string) :EventSource {
    return new EventSource(url);
  }
}
