import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { EndpointsService } from "./endpoints.service";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError, lastValueFrom, Observable, throwError } from "rxjs";
import { User } from "@shared/models/User";
import { UserRegisterDto } from "../../store/models/userRegisterDto";
import { SiteConfigDTO } from "@shared/models/SiteConfigDTO";
import { Area } from "@shared/models/Area";
import { FrontPageArea } from "@shared/models/FrontPageArea";
import { BrandingSettings } from '@shared/models';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private get apiUrl() {
    return environment.api_url;
  }

  constructor(private endpoints: EndpointsService,
              private httpClient: HttpClient) {}

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was:`, error.error);
    }

    if (error.status === 401) {
      console.log(error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => 'Something went wrong; please try again later.')
  }

  public login(email: String, password: String): Promise<User> {
    const { login } = this.endpoints;
    const payload: object = {
      email,
      password
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post<User>(`${this.apiUrl}/${login}`, payload)
    .pipe(
      catchError(this.handleError)
    ));
  }

  public register(user: UserRegisterDto): Promise<any> {
    const { register } = this.endpoints;
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post<User>(`${this.apiUrl}/${register}`, user)
    .pipe(
      catchError(this.handleError)
    ));
  }

  public async getAppSettings(): Promise <SiteConfigDTO>  {
    const { settings } = this.endpoints;
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    const response: any = await lastValueFrom(this.httpClient.get(`${this.apiUrl}/${settings}?clean=true`)
      .pipe(
        catchError(this.handleError)
    ));

    if (response.status) {
      return response.package.data as SiteConfigDTO;
    }
    return {} as SiteConfigDTO;
  }

  public getAppSettingsRequest() {
    const { settings } = this.endpoints;
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.httpClient.get<{ package: {data: SiteConfigDTO} }>(`${this.apiUrl}/${settings}?clean=true`);//.pipe((data) => {console.log(data)});
  }

  public async getAreas(): Promise <Area[]> {
    const { areasEndpoint } = this.endpoints;
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    const response: any = await lastValueFrom(this.httpClient.get(`${this.apiUrl}/${areasEndpoint}?clean=true`)
      .pipe(
        catchError(this.handleError)
    ));

    if (response.status) {
      return response.package as Area[];
    }
    return [];
  }

  public async getFrontPageContent(): Promise <FrontPageArea[]> {
    const { frontPageAreas } = this.endpoints;
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    const response: any = await lastValueFrom(this.httpClient.get(`${this.apiUrl}/${frontPageAreas}`)
      .pipe(
        catchError(this.handleError)
    ));

    if (response.status) {
      return response.package.data as FrontPageArea[];
    }
    return [];
  }

  public async getBrandingSettings(): Promise <BrandingSettings> {
    const { brandingSettings } = this.endpoints;
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    const response: any = await lastValueFrom(this.httpClient.get(`${this.apiUrl}/${brandingSettings}`)
      .pipe(
        catchError(this.handleError)
    ));

    if (response.status) {
      return response.package.data as BrandingSettings;
    }
    return {uuid: 'branding'};
  }

  public getAreasRequest() {
    const { areasEndpoint } = this.endpoints;
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return this.httpClient.get<{ package: Area[] }>(`${this.apiUrl}/${areasEndpoint}?clean=true`);
  }

  public forgotPassword(mail: String): Promise<any> {
    const { forgotpassword } = this.endpoints;
    const payload: object = {
      mail
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post(`${this.apiUrl}/${forgotpassword}`, payload)
      .pipe(
        catchError(this.handleError)
    ));
  }

  /**
   * Reset password.
   */
  public resetPassword(name: String, temp_pass: String, new_pass: String): Promise<any> {
    const { resetpassword } = this.endpoints;
    const payload: object = {
      name,
      temp_pass,
      new_pass
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post(`${this.apiUrl}/${resetpassword}`, payload)
      .pipe(
        catchError(this.handleError)
      ));
  }

  /**
   * ota sms code check
   */
  public otaSmsCodeCheck(mail: String, ota_code: String): Promise<any> {
    const { otaSmsCode } = this.endpoints;
    const payload: object = {
      mail,
      ota_code
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post(`${this.apiUrl}/${otaSmsCode}`, payload)
      .pipe(
        //catchError(this.handleError)
      ));
  }

  public resendOtpCode( mail_or_phone: String): Promise<any> {
    const { otaSmsEmailCodeResend } = this.endpoints;
    const payload: object = {
      mail_or_phone
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post(`${this.apiUrl}/${otaSmsEmailCodeResend}`, payload)
      .pipe(
        catchError(this.handleError)
      ));
  }

  /**
   * Update user profile Picture
   */
  public updateUserProfilePicture(accessToken: string, base_64_image : string, file_name : string): Promise<any> {
    const { profileImageUpdate } = this.endpoints;
    const payload: object = {
      accessToken,
      base_64_image,
      file_name
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post(`${this.apiUrl}${profileImageUpdate}`, payload)
      .pipe(
        catchError(this.handleError)
      ));
  }


  /**
   * Update user profile.
   */
  public updateUserProfile(accessToken: string, user : User): Promise<any> {
    const { update_user_profile } = this.endpoints;
    const payload: object = {
      accessToken,
      user
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post(`${this.apiUrl}${update_user_profile}`, payload)
      .pipe(
        catchError(this.handleError)
      ));
  }

  /**
   * reports a user.
   * @param accessToken
   * @param blockUserUUID
   * @param type
   * @param reason
   */
  public reportUser(accessToken: string, blockUserUUID: string, type: string, reason: string): Promise<any> {
    const { report_user } = this.endpoints;
    const payload: object = {
      accessToken,
      blockUserUUID,
      type,
      reason
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post(`${this.apiUrl}${report_user}`, payload)
      .pipe(
        catchError(this.handleError)
      ));
  }


  public checkEmailForImportedUser(email:string): Promise<any> {
    const { imported_user_check } = this.endpoints;
    const payload: object = {
      email
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post(`${this.apiUrl}${imported_user_check}`, payload)
      .pipe(
        catchError(this.handleError)
      ));
  }

  /**
   * Check the user token is still good.
   * @param accessToken
   */
  public checkAccess(accessToken: string): Promise<any> {
    const { checkAccess } = this.endpoints;
    const payload: object = {
      accessToken
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post(`${this.apiUrl}${checkAccess}`, payload)
      .pipe(
        catchError(this.handleError)
      ));
  }


  public checkAreaTicketApiCall(AreaUUID: string, UserUUID: string): Promise<any> {
    const {checkAreaTicket} = this.endpoints;
    const payload: object = {
      AreaUUID,
      UserUUID
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post(`${this.apiUrl}${checkAreaTicket}`, payload)
      .pipe(
        catchError(this.handleError)
      ));
  }


  public checkAreaForOrderAndUserCall(AreaUUID: string, UserUUID: string, orderNumber: string): Promise<any> {
    const {checkAreaForOrderAndUser} = this.endpoints;
    const payload: object = {
      AreaUUID,
      UserUUID,
      orderNumber
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    return lastValueFrom(this.httpClient.post(`${this.apiUrl}${checkAreaForOrderAndUser}`, payload)
      .pipe(
        catchError(this.handleError)
      ));
  }

  public async getUserProtectedAttributes(accessToken: string): Promise<any> {
    const { getUserProtectedAttributes } = this.endpoints;
    const payload: object = {
      accessToken
    };
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json');
    const response: any = await lastValueFrom(this.httpClient.post(`${this.apiUrl}${getUserProtectedAttributes}`, payload)
      .pipe(
        catchError(this.handleError)
      ));
    if (response.status) {
      return response.package;
    }
    return {};
  }






}
