import {Component, OnDestroy, OnInit} from '@angular/core';
import {ScreenSizeService} from "@gatho/features/lobby/services/screen-size.service";
import { WhitelabelHelperService } from '@gatho/services/whitelabelHelpers';
import {Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'app-main-landing',
  templateUrl: './main-landing.component.html',
  styleUrls: ['./main-landing.component.scss']
})
export class MainLandingComponent implements OnInit, OnDestroy {

  isMobile!: boolean;
  screenSizeServiceSub: Subscription;
  public logoPath: string = '../../../../assets/images/logos/gatho-logo-gradient.svg';
  public bgPath: string = '../../../../assets/images/lobby-bg.png';
  public headerVideoPath = '../../../../assets/media/gatho-home-page-video.mp4';
  sectionOneHtml = '<h3>Select a category</h3><p>What are in you the mood for today? Who do you want to hang with?</p><p>Maybe a bit of live sport action? Some raw tunes from an Indi artist or that hypnotic vibe of a nightclub.</p><p>Who\'ll be meeting up tonight and where?</p><p>Click the category to see what\'s live.</p>'
  sectionTwoHtml = '<h3>Choose a venue</h3><p>Check into to your favourite a New York bar... How about a festival in Jamaica?</p><p>We have a venue open around the clock and the crowd is pumping!</p><p>Select a venue and check out the scene.</p><p>Better still, organise to meet the gang there.</p>'
  sectionThreeHtml = '<h3>Enter a Room</h3><p>You\'re in! Now to choose a room.</p><p>What will it be?</p><p>A \'Lounge\' for some mingling with the crowd, or a \'Stage\' to be part of a live entertainment broadcast straight to your laptop or device.</p><p>Grab a drink, order some food and get settled for the night.</p>\n'
  sectionOneImagePath = '../../../assets/images/gatho-welcome.png';
  sectionTwoImagePath = '../../../assets/images/gatho-events.png';
  sectionThreeImagePath = '../../../assets/images/gatho-room.png';
  renderLogo = false;

  constructor(
    private _screenSizeService: ScreenSizeService,
    private _whitelabelService: WhitelabelHelperService,
    public router: Router
  ) {
    this.screenSizeServiceSub = _screenSizeService.screenSizeUpdated$.subscribe(
      (isMobile) => (this.isMobile = isMobile)
    );

  }

  ngOnInit(): void {
    if (this._whitelabelService.isWhitelabeled()) {
      this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.screenSizeServiceSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderLogo = true;
    }, 2000);
  }

}
