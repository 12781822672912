<section class="container">
  <div class="row py-10 px-10">
    <div class="column">
      <span>Login first for lobby access</span>
      <a mat-flat-button class="is-primary" href="/login">Login</a>
    </div>
    <div class="column">
      <a mat-flat-button class="is-primary" href="/verify-age">Verify age</a>
      <a mat-flat-button class="is-primary" href="/sign-up">Sign up</a>
      <a mat-flat-button class="is-primary" href="/sigm-up/ota">Verify OTP</a>
      <a mat-flat-button class="is-primary" href="/password-reset">Password Reset</a>
    </div>
  </div>
</section>

<section class="container">
  <div class="row py-10 px-10">
    <div class="column">
      <h4>Development Resources</h4>
      <a mat-flat-button class="is-primary" href="/webrtc-poc">WebRTC POC</a>
      <a mat-flat-button class="is-primary" href="/media-room">Media room</a>
      <a mat-flat-button class="is-primary" href="/area/0b4d54d8-509e-4485-9224-1eb2de216939">Cocktail Room</a>
    </div>
  </div>
</section>
