import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EndpointsService {

  constructor() { }
  public settings = 'settings';
  public areasEndpoint = 'areas';
  public frontPageAreas = 'frontpage-data';
  public login = 'user/login';
  public register = 'user/register';
  public forgotpassword = 'forgotpassword';
  public resetpassword = 'reset-password';
  public otaSmsCode= 'ota-sms-check';
  public otaSmsEmailCodeResend= 'ota-sms-resend';
  public profileImageUpdate = '/user/update-profile-picture';
  public update_user_profile = '/user/update-profile';
  public report_user = '/user/report';
  public imported_user_check = '/check-email';
  public checkAccess = '/access-check';
  public checkAreaTicket = '/user/check-user-has-ticket-for-area';
  public checkAreaForOrderAndUser = '/user/check-user-has-order-number-for-area';
  public getUserProtectedAttributes = '/get-user-protected-attributes';
  public brandingSettings = '/branding-settings';

}
