<h4 mat-dialog-title>Password Reset</h4>
<mat-dialog-content class="mat-typography">
  <div *ngIf="sucessMessage">
    <p class="text-center">
      Password instructions have been sent. Please check you spam folder in case
      you don't receive it in your primary folder.
    </p>
  </div>

  <form
    class="passwordForm"
    *ngIf="!sucessMessage"
    name="passwordForm"
    #f="ngForm"
    (ngSubmit)="onSubmit(f)"
  >
    <ng-container *ngIf="!loading; else spinner">
      <div class="mat-error" *ngIf="errorMessage">{{ errorMessage }}</div>
      <mat-form-field class="field-email" appearance="fill" class="has-bgLight">
        <mat-label>EMAIL</mat-label>
        <input
          matInput
          type="email"
          name="email"
          autocomplete="email"
          placeholder="EMAIL"
          [ngModel]="payload.email"
          #email="ngModel"
          required
          email
          tabindex="1"
          autofocus
        />
        <mat-error *ngIf="email.touched && email.invalid" class="mat-error"
          >Please enter your email address</mat-error
        >
      </mat-form-field>
      <p class="text-center">
        We’ll send you a link to create your new password.
      </p>

      <button type="submit" class="is-primary" mat-flat-button>
        SEND REQUEST
      </button>
    </ng-container>
    <ng-template #spinner>
      <div class="center">
        <mat-spinner></mat-spinner>
        <p class="text-center">Loading...</p>
      </div>
    </ng-template>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-icon-button
    [mat-dialog-close]="true"
    aria-label="close icon"
    title="close"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-dialog-actions>
