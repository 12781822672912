import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  public content: string | unknown = '';

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<TermsComponent>,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.content = _bottomSheetRef.containerInstance._config.data;
    this._bottomSheetRef.afterDismissed().subscribe(() => {
      this._router.navigate(['sign-up'], { relativeTo: this._route });
    });
  }

  ngOnInit(): void {}

  selectAction(_event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
  }
}
