import { Component, OnInit } from '@angular/core';
import { SiteConfigDTO } from '@shared/models/SiteConfigDTO';
import { ApiService } from '../../../../services/api/api.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-disclaimer-pages',
  templateUrl: './disclaimer-pages.component.html',
  styleUrls: [
    './disclaimer-pages.component.scss',
    '../../../pages/sign-up/sign-up.component.scss',
  ],
})
export class DisclaimerPagesComponent implements OnInit {
  public settings: SiteConfigDTO = {} as SiteConfigDTO;
  public contentTc!: string;
  public contentPrivacy!: string;
  public contentReturns!: string;
  public contentRulesForContrib!: string;
  isTc!: boolean;
  isPrivacy!: boolean;
  isReturns!: boolean;
  isRulesForContrib!: boolean;
  public loading = false;

  constructor(private _router: Router, private _api: ApiService) {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isTc = this._router.url.includes('/tc');
        this.isPrivacy = this._router.url.includes('/privacy');
        this.isReturns = this._router.url.includes('/returns');
        this.isRulesForContrib = this._router.url.includes('/rules');
      }
    });
  }

  ngOnInit(): void {
    this.getSettings();
  }

  /* Get the application settings. */
  async getSettings() {
    try {
      this.loading = true;
      // this object is cached.
      const settingsData: SiteConfigDTO = await this._api.getAppSettings();
      this.settings = settingsData;

      this.contentTc = this.settings.terms;
      this.contentPrivacy = this.settings.privacy;
      this.contentReturns = this.settings.returns;
      this.contentRulesForContrib = this.settings.rules_for_contrib;
      this.loading = false;
    } catch (err) {
      console.log('err', err);
    }
  }

  navigateToHome() {
    this._router.navigate(['/']);
  }
}
