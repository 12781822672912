import { createReducer, on } from '@ngrx/store';
import * as AccountActions from './account.actions';
import { User } from "@shared/models/User";

export const accountFeatureKey = 'account';

export interface AccountState {
  accountLoaded: boolean;
  user?: User;
}

export const initialState: AccountState = {
  accountLoaded: false
};

export const accountReducer = createReducer(
  initialState,
  on(AccountActions.loadAccountSuccess, (state, action) => {
    return {
      ...state,
      accountLoaded: true,
      user: action.user
    }
  }),
  on(AccountActions.updateAccount, (state, action) => {
    return {
      ...state,
      user: action.user
    }
  })
);
