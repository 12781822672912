import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  init as sentryInit,
  routingInstrumentation as sentryRoutingInstrumentation
} from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import "webrtc-adapter";

sentryInit({
  environment: environment.name,
  dsn: "https://edda711cffd84f60ac2badcad6d1418a@sentry.atech.host/12",
  integrations: [
    new BrowserTracing({
      // TODO add dynamic host here?
      tracingOrigins: ["https://frontend-dev-gatho.lndo.site", "https://www-dev-gatho.atech.host", "https://live-dev-gatho.atech.host", "https://live.gatho.app"],
      routingInstrumentation: sentryRoutingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

const boot = () => 
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));

fetch('config.json')
  .then((response) => {
    const type = response.headers.get('content-type');
    if (!type || !type.includes('application/json')) return null;
    return response.ok ? response.json() : null;
  })
  .then((json) => {
    if (json && json.api) {
      // If valid json update environment with new urls
      environment.socket_server = json.api
      environment.api_url = json.api
      environment.admin_url = json.admin
    }
    // Continue angular setup
    boot();
  })
  .catch(err => console.debug(err));