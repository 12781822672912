import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { NgForm } from "@angular/forms";
import {ForgotPasswordComponent} from "../pages/login/forgot-password/forgot-password.component";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public loading = false;
  public hide = true;
  public showSentMessage = false;
  public showErrorMessage = false;
  public payload = {
    "name": '',
    "temp_pass": '',
    "new_pass": '',
  };
  constructor(
    private _router: Router,
    private _api: ApiService,
    private _matDialog: MatDialog,
    private matDialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  navigateToLogin() {
    this._router.navigate(['login']);
  }

  openGetPassword() {
    const dialogConfig = {
      maxWidth: '44rem',
      width: '44rem',
      panelClass: ['gathoDialog'],
    };
    this.matDialog.open(ForgotPasswordComponent, dialogConfig);
  }



  // @todo add validation here.

  onSubmit(f: NgForm): void {
    if (f.valid) {
      const name = f.value.name;
      const temp_pass = f.value.temp_pass;
      const new_pass = f.value.new_pass;

      this._api.resetPassword(name, temp_pass, new_pass).then((responce: any) => {
        if (responce.status === true) {
          this.showSentMessage = true;
        }
        this.loading = false;
      }).catch((e: Error) => {
        this.loading = false;
        this.showSentMessage = false;
        this.showErrorMessage = true;
      });
    }
  }

}
