import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-returns-policy',
  templateUrl: './returns-policy.component.html',
  styleUrls: ['./returns-policy.component.scss'],
})
export class ReturnsPolicyComponent implements OnInit {
  public content: string | unknown = '';

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ReturnsPolicyComponent>,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.content = _bottomSheetRef.containerInstance._config.data;
    this._bottomSheetRef.afterDismissed().subscribe(() => {
      this._router.navigate(['sign-up'], { relativeTo: this._route });
    });
  }

  ngOnInit(): void {}

  selectAction(_event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
  }
}
