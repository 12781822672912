<marketing-nav></marketing-nav>
<!--  TODO: implement header background as image-->
<header>
  <div class="gathoLogo">
    <img [src]="logoPath" alt="gatho-logo" title="Gatho"/>
  </div>
  <div class="header-content">
    <div class="content-text">
      <h3>LIVE. EVENT. EXPERIENCE</h3>
      <p>
        The only entertainment platform that puts you in the room to meet, mingle and be part of live entertainment event scene... from anywhere in the world.
      </p>
      <h2>NOW OPEN</h2>
      <h3>See you at Gatho!</h3>
      <div class="content-text__actions">
        <button mat-flat-button [routerLink]="'/login'" class="is-primary">CLICK TO ENTER</button>
        <button mat-flat-button [routerLink]="'/become-gatho-venue'" class="is-secondary">HOST AN EVENT</button>
      </div>
    </div>
    <div class="content-video">
      <video controls>
        <source [src]="headerVideoPath">
      </video>
    </div>
  </div>
</header>
<app-logo-section title="NEW VENUES & EVENTS ONLINE DAILY"></app-logo-section>
<app-content-section [content]="sectionOneHtml" [imagePath]="sectionOneImagePath"></app-content-section>
<app-content-section [content]="sectionTwoHtml" [imagePath]="sectionTwoImagePath" [reversed]="true"></app-content-section>
<app-content-section [content]="sectionThreeHtml" [imagePath]="sectionThreeImagePath"></app-content-section>
<footer>
  <div class="text-links">
    <ul>
      <li>
        <a [routerLink]="'/privacy'">Privacy</a>
      </li>
      <li>
        <a [routerLink]="'/tc'">Terms & Conditions</a>
      </li>
    </ul>
  </div>
  <div class="icon-links">

  </div>
</footer>
