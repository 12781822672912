import { Component, ElementRef } from '@angular/core';
import { UserService } from './services/user/user.service';
import { BrandingService } from './services/branding/BrandingService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'gatho';

  constructor(
    private _userService: UserService,
    private _elementRef: ElementRef,
    private _branding: BrandingService
  ) {
    _userService.loadFromLocalStorage();
    this.setBranding();
  }

  // Apply branding config that isn't specific to sub-components (e.g: buttons)
  setBranding() {
    this._branding.setCssProperty(this._elementRef, '--button-primary-text-color', 'primaryButtonTextColor');
    this._branding.setCssProperty(this._elementRef, '--button-primary-start-color', 'primaryButtonStartColor');
    this._branding.setCssProperty(this._elementRef, '--button-primary-end-color', 'primaryButtonEndColor');
  }

  ngOnInit(): void {
    this.safariAddressBarState();
  }

  isIOSDevice() {
    // return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }

  safariAddressBarState() {
    if (this.isIOSDevice()) {
      // Hide address bar
      window.addEventListener('load', function () {
        setTimeout(function () {
          window.scrollTo(0, 1);
        }, 1000);
      });
    }
  }
}
