import { createAction, props } from "@ngrx/store";
import { User } from "@shared/models/User";

export const loadAccountSuccess = createAction(
  '[Account] Load Account Success',
  props<{ user: User }>()
);

export const updateAccount = createAction(
  '[Account] Update Account',
  props<{ user: User }>()
);

export const clear = createAction(
  '[Account] Clear'
);
