import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { NgForm } from "@angular/forms";
import { ApiService } from "@gatho/services/api/api.service";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public loading = false;
  public errorMessage?: string;
  public sucessMessage: boolean;
  public payload = {
    email: "",
  };

  constructor(private dialogRef: MatDialogRef<ForgotPasswordComponent>, private _api: ApiService,) {
    dialogRef.disableClose = true;
    this.sucessMessage = false;
  }

  ngOnInit(): void {
  }
  onSubmit(f: NgForm): void {
    //this.sucessMessage = true;
    if (f.valid) {
      this.loading = true;
      delete this.errorMessage;
      const email = f.value.email;
      // Locally if cant send email
      this._api.forgotPassword(email).then((responce: any) => {
        this.loading = false;
        this.sucessMessage = true;
      }).catch((e: Error) => {
        this.loading = false;
        this.sucessMessage = true;
      });
    }
  }

}
