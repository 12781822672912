import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ApiService } from '../../../../services/api/api.service';

@Component({
  selector: 'app-resend-ota',
  templateUrl: './resend-ota.component.html',
  styleUrls: ['./resend-ota.component.scss'],
})
export class ResendOtaComponent implements OnInit {
  public settings: any;
  public phoneOrEmail: string = 'phone';
  public codeHasSent: boolean = false;
  public phoneOrEmailtoSend: string = '';
  public errorMessage?: string = '';
  public mylocalStorage: typeof localStorage = localStorage;
  constructor(
    private dialogRef: MatDialogRef<ResendOtaComponent>,
    private _apiService: ApiService
  ) {
    this.settings = dialogRef._containerInstance._config.data;
  }
  ngOnInit(): void {
    if (this.settings.otp_send_via_email) {
      this.phoneOrEmail = 'email';
    }
    const userdata = this.mylocalStorage.getItem('user_info');
    if (userdata) {
      const userdataDecoded = JSON.parse(userdata);
      if (userdataDecoded && userdataDecoded.country) {
        if (userdataDecoded.country === 'us') {
          this.phoneOrEmail = 'email';
        }
      }
    }
  }
  onSubmit(f: NgForm): void {
    this.errorMessage = '';
    if (this.phoneOrEmailtoSend) {
      this._apiService
        .resendOtpCode(this.phoneOrEmailtoSend)
        .then((response) => {
          if (response.status === true) {
            this.codeHasSent = true;
          }
        })
        .catch((e: Error) => {
          console.log(e);
          this.errorMessage = 'Something has gone wrong.';
        });
    } else {
      this.errorMessage = 'All fields are required.';
    }
  }
}
