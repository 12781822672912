import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private _snackBar: MatSnackBar) { }

  public open(message: string, configOverrides?: MatSnackBarConfig){
    const defaultConfig = {
      duration: 4000,
      panelClass: 'success',
      verticalPosition: 'top' as MatSnackBarVerticalPosition,
    };
    const config = {...defaultConfig, ...configOverrides};
    this._snackBar.open(message, '', config);
  }
}
