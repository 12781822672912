import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })

export class WhitelabelHelperService {

  constructor(@Inject(DOCUMENT) private document: any) {

  }

  private domain = this.document.location.hostname;

  public getInstanceName() {
    // Name -> domain regex pattern
    const instances = {
      'remax': 'remaxgc'
    };
    let instanceName = 'gatho';
    for (const [name, pattern] of Object.entries(instances)) {
      if (this.domain.includes(pattern)) {
        instanceName = name;
        break;
      }
    }
    return instanceName;
  }

  public isWhitelabeled() {
    const gathoDomains = [
      'frontend-dev-gatho.lndo.site',
      'www-dev-gatho.atech.host',
      'gatho.app'
    ]
    if (!gathoDomains.includes(this.domain)) {
      return true;
    }
    return false;
  }
}
