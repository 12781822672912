<nav>
    <img [src]="logoPath" alt="gatho-logo" title="Gatho"/>
  
    <div class="nav-items">
      <ng-container *ngIf="isMobile; else desktopNav" >
        <button class="is-primary" mat-flat-button [routerLink]="'/login'">
          <span class="mat-button-wrapper">Get Started</span>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [routerLink]="'/gatho'">
  <!--          <mat-icon>dialpad</mat-icon>-->
            <span>HOME</span>
          </button>
          <button mat-menu-item [routerLink]="'/become-gatho-venue'">
  <!--          <mat-icon>notifications_off</mat-icon>-->
            <span>HOST AN EVENT</span>
          </button>
          <button mat-menu-item [routerLink]="'/login'">
            <!--          <mat-icon>notifications_off</mat-icon>-->
            <span>CLICK TO ENTER</span>
          </button>
        </mat-menu>
      </ng-container>
      <ng-template #desktopNav>
        <a [routerLink]="'/gatho'">
          <div>HOME</div>
        </a>
        <a [routerLink]="'/become-gatho-venue'">
          <div>HOST AN EVENT</div>
        </a>
        <button mat-flat-button [routerLink]="'/login'" class="is-primary">CLICK TO ENTER</button>
      </ng-template>
    </div>
  </nav>