<div class="template__signUp">
  <div class="bgSign" [style.background-image]="'url(' + settings.login_page_bg_url + ')'">
    <div class="signupOuterBox gradientTheme hasScrollbarLight">
      <div class="signupOuterContent">
        <button
          type="button"
          (click)="navigateToLogin()"
          class="btnBack is-light"
          mat-icon-button
          aria-label="back arrow"
          title="Back to Login"
        >
          <mat-icon>keyboard_backspace</mat-icon>
        </button>
        <strong class="forgotLink is-light">
          Already have an account, but can't log in? Reset your password by
          clicking<a variant="info" (click)="openGetPassword()">here</a>.
        </strong>
      </div>
      <form #f="ngForm" (ngSubmit)="onSubmit(f)">
        <div class="signupInnerBox">
          <h4 class="is-light titleSignUp">Sign Up</h4>
          <div class="userProfilePic">
            <button class="btnUpload" type="button" title="upload">
              <div class="avatar indicator edit-lg">
                <div class="rounded-full">
                  <input
                    (change)="upload($event)"
                    type="file"
                    name="file"
                    id="file"
                    value=""
                    title="Profile Picture"
                  />
                  <button
                    class="btnUpload"
                    type="button"
                    mat-icon-button
                    aria-label="plus icon"
                    *ngIf="!profilePicPath"
                  >
                    <input
                      (change)="upload($event)"
                      type="file"
                      name="file"
                      id="file"
                      value=""
                      title="upload Profile Picture"
                    />
                    <mat-icon>add</mat-icon>
                  </button>
                  <img
                    *ngIf="profilePicPath"
                    [src]="profilePicPath"
                    alt="img"
                  />
                </div>
                <span
                  *ngIf="profilePicPath"
                  class="indicator-item badge bg-primary px-3"
                >
                  <button type="button">
                    <input
                      (change)="upload($event)"
                      type="file"
                      name="file"
                      id="fileEdit"
                      value=""
                      title="Edit Profile Picture"
                    />
                    <mat-icon>edit</mat-icon>
                  </button>
                </span>
              </div>
            </button>
            <p>Profile Picture</p>
            <mat-error
              class="mat-error is-white"
              *ngIf="fileUploadErrorMessage"
            >
              {{ fileUploadErrorMessage }}
            </mat-error>
          </div>

          <div class="signUpFormOuter">
            <div class="signUpInnerInputContainer">
              <mat-error
                class="mat-error is-white text-center"
                *ngIf="errorMessage"
                >{{ errorMessage }}</mat-error
              >
              <mat-form-field appearance="fill">
                <mat-label>Display Name</mat-label>
                <input
                  matInput
                  type="text"
                  name="displayName"
                  autocomplete="displayName"
                  placeholder="Display Name"
                  [(ngModel)]="payload.displayName"
                  required
                  autofocus
                  [formControl]="displayName"
                />
                <mat-error
                  *ngIf="displayName.touched && displayName.invalid"
                  class="mat-error is-white"
                  >{{
                    this.formatErrorMessage(
                      displayName.errors,
                      "Please enter your display name"
                    )
                  }}</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Full Name</mat-label>
                <input
                  matInput
                  type="text"
                  name="fullName"
                  autocomplete="fullName"
                  placeholder="Full Name"
                  [(ngModel)]="payload.fullName"
                  required
                  [formControl]="fullName"
                />
                <mat-error
                  *ngIf="fullName.errors && fullName.invalid"
                  class="mat-error is-white"
                  >{{
                    this.formatErrorMessage(
                      fullName.errors,
                      "Please enter your full name"
                    )
                  }}</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Mobile Number</mat-label>
                <ngx-mat-intl-tel-input
                  [preferredCountries]="['au', 'nz', 'us']"
                  [enablePlaceholder]="true"
                  placeholder="+61400000000"
                  name="mobile"
                  describedBy="phoneInput"
                  [(ngModel)]="payload.mobile"
                  [required]="true"
                  [formControl]="mobile"
                  (countryChanged)="handleCountryChange($event)"
                >
                </ngx-mat-intl-tel-input>
                <mat-error
                  *ngIf="mobile.touched && mobile.invalid"
                  class="mat-error is-white"
                  >{{
                    this.formatErrorMessage(
                      mobile.errors,
                      "Please enter your mobile number"
                    )
                  }}</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  type="email"
                  name="email"
                  autocomplete="email"
                  placeholder="Email"
                  [(ngModel)]="payload.email"
                  #email="ngModel"
                  email
                  required
                />

                <mat-error
                  *ngIf="email.value === '' && email.touched"
                  class="mat-error is-white"
                  >Please enter your email address</mat-error
                >
                <mat-error
                  *ngIf="email.value !== '' && email.touched && email.invalid"
                  class="mat-error is-white"
                  >{{ emailValidationErrorMessage }}</mat-error
                >
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-label>Password</mat-label>
                <input
                  matInput
                  type="password"
                  name="password"
                  placeholder="Password"
                  [(ngModel)]="payload.password"
                  #password="ngModel"
                  required
                />
                <mat-error
                  *ngIf="password.touched && password.invalid"
                  class="mat-error is-white"
                  >Please enter your password</mat-error
                >
              </mat-form-field>

              <mat-form-field *ngIf="!isMobile" appearance="fill">
                <mat-label>DOB</mat-label>
                <input
                  matInput
                  [min]="minDate"
                  [max]="maxDate"
                  [matDatepicker]="pickerDesk"
                  placeholder="DD/MM/YYYY"
                  name="dob"
                  autocomplete="dob"
                  [(ngModel)]="payload.dob"
                  required
                  [formControl]="dob"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="pickerDesk"
                  title="Datepicker calendar"
                ></mat-datepicker-toggle>
                <mat-datepicker #pickerDesk></mat-datepicker>
                <mat-error
                  *ngIf="dob.touched && dob.invalid"
                  class="mat-error is-white"
                  >{{
                    this.formatErrorMessage(
                      dob.errors,
                      "Please enter a valid date of birth. For example, 31/12/1999"
                    )
                  }}</mat-error
                >
              </mat-form-field>

              <mat-form-field *ngIf="isMobile" appearance="fill">
                <mat-label>DOB</mat-label>
                <input
                  matInput
                  [min]="minDate"
                  [max]="maxDate"
                  [matDatepicker]="pickerMob"
                  placeholder="DD/MM/YYYY"
                  name="dob"
                  autocomplete="dob"
                  [(ngModel)]="payload.dob"
                  required
                  [formControl]="dob"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="pickerMob"
                  title="Datepicker calendar"
                ></mat-datepicker-toggle>
                <mat-datepicker touchUi #pickerMob></mat-datepicker>
                <mat-error
                  *ngIf="dob.touched && dob.invalid"
                  class="mat-error is-white"
                  >{{
                    this.formatErrorMessage(
                      dob.errors,
                      "Please enter a valid date of birth. For example, 31/12/1999"
                    )
                  }}</mat-error
                >
              </mat-form-field>

              <hr style="margin: 1rem auto;">
              <h3 class="is-light" style="text-align: center;">Social Media Links</h3>
              <p class="is-light" style="font-size: .75rem;"><strong>Please Note:</strong> If any social links are added, they will be visible to any Gatho users. Please enter only your username/handle/ID for each network, excluding any symbols such as @</p>

              <mat-form-field appearance="fill">
                <mat-label>Facebook</mat-label>
                <input
                  matInput
                  type="text"
                  name="facebook"
                  placeholder="Facebook url"
                  [(ngModel)]="payload.facebook"
                  #facebook="ngModel"
                />
                <mat-error
                  *ngIf="facebook.touched && facebook.invalid"
                  class="mat-error is-white"
                >Please enter your Facebook url</mat-error
                >
              </mat-form-field>


              <mat-form-field appearance="fill">
                <mat-label>Instagram</mat-label>
                <input
                  matInput
                  type="text"
                  name="instagram"
                  placeholder="Instagram url"
                  [(ngModel)]="payload.instagram"
                  #facebook="ngModel"
                />
                <mat-error
                  *ngIf="instagram.touched && instagram.invalid"
                  class="mat-error is-white"
                >Please enter your Instagram url</mat-error
                >
              </mat-form-field>


              <mat-form-field appearance="fill">
                <mat-label>Twitter</mat-label>
                <input
                  matInput
                  type="text"
                  name="twitter"
                  placeholder="Instagram url"
                  [(ngModel)]="payload.twitter"
                  #facebook="ngModel"
                />
                <mat-error
                  *ngIf="twitter.touched && twitter.invalid"
                  class="mat-error is-white"
                >Please enter your Twitter url</mat-error
                >
              </mat-form-field>


              <mat-form-field appearance="fill">
                <mat-label>TikTok</mat-label>
                <input
                  matInput
                  type="text"
                  name="tiktok"
                  placeholder="TikTok url"
                  [(ngModel)]="payload.tiktok"
                  #facebook="ngModel"
                />
                <mat-error
                  *ngIf="tiktok.touched && tiktok.invalid"
                  class="mat-error is-white"
                >Please enter your TikTok url</mat-error
                >
              </mat-form-field>


              <mat-form-field appearance="fill">
                <mat-label>YouTube</mat-label>
                <input
                  matInput
                  type="text"
                  name="youtube"
                  placeholder="YouTube url"
                  [(ngModel)]="payload.youtube"
                  #facebook="ngModel"
                />
                <mat-error
                  *ngIf="youtube.touched && youtube.invalid"
                  class="mat-error is-white"
                >Please enter your Youtube url</mat-error
                >
              </mat-form-field>

              <div class="fieldCheckbox">
                <mat-checkbox
                  class="is-light"
                  name="marketingOptIn"
                  [(ngModel)]="payload.marketingOptIn"
                >
                  Opt-in to marketing material
                </mat-checkbox>
              </div>

              <div class="fieldCheckbox">
                <div class="fieldCheckbox__withLink">
                  <mat-checkbox
                    class="is-light"
                    required
                    name="termsCheck"
                    [formControl]="termsCheck"
                    [(ngModel)]="termsCheckbox"
                  >
                    I agree to</mat-checkbox
                  >
                  <span class="is-light fieldCheckbox__link"
                    ><a routerLink="/sign-up" [queryParams]="{ terms: true }"
                      >Terms</a
                    >
                    *</span
                  >
                </div>

                <mat-error
                  *ngIf="termsCheck.touched && termsCheck.invalid"
                  class="mat-error is-white"
                  >{{
                    this.formatErrorMessage(
                      termsCheck.errors,
                      "Please read and agree to the Terms of Use"
                    )
                  }}</mat-error
                >
              </div>

              <div class="fieldCheckbox">
                <div class="fieldCheckbox__withLink">
                  <mat-checkbox
                    class="is-light"
                    required
                    name="privacyCheck"
                    [(ngModel)]="privacyCheckbox"
                    [formControl]="privacyCheck"
                  >
                    I accept</mat-checkbox
                  >
                  <span class="is-light fieldCheckbox__link"
                    ><a routerLink="/sign-up" [queryParams]="{ privacy: true }"
                      >Privacy Policy</a
                    >
                    *</span
                  >
                </div>
                <mat-error
                  *ngIf="privacyCheck.touched && privacyCheck.invalid"
                  class="mat-error is-white"
                  >{{
                    this.formatErrorMessage(
                      privacyCheck.errors,
                      "Please read and accept the Privacy Policy"
                    )
                  }}</mat-error
                >
              </div>

              <div class="nextbtn">
                <button mat-stroked-button type="submit" [disabled]="!f.valid">
                  NEXT
                </button>
              </div>

              <div class="privacyText">
                By signing up you are agreeing to our
                <a routerLink="/sign-up" [queryParams]="{ privacy: true }"
                  >Privacy Policy</a
                >,
                <a routerLink="/sign-up" [queryParams]="{ terms: true }"
                  >Terms of Use</a
                >,
                <a routerLink="/sign-up" [queryParams]="{ returns: true }">
                  Returns</a
                >
                and
                <a routerLink="/sign-up" [queryParams]="{ rules: true }"
                  >Rules for Contributors</a
                >.
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
