import { Component, OnInit } from '@angular/core';
import { UserService } from "../../../services/user/user.service";
import { Router } from '@angular/router';

@Component({
  template: ''
})
export class LogoutComponent implements OnInit {

  constructor(
    private _userService: UserService,
    private _router: Router,
  ) {
    this._userService.clearUserData();
  }

  ngOnInit(): void {
    this._router.navigate(['/gatho']);
  }

}
