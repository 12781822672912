import {Component, Input, OnInit} from '@angular/core';
import {ScreenSizeService} from "@gatho/features/lobby/services/screen-size.service";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: 'marketing-nav',
  templateUrl: './marketing-nav.component.html',
  styleUrls: ['./marketing-nav.component.scss']
})

export class MarketingNavComponent implements OnInit {

    isMobile!: boolean;
    screenSizeServiceSub: Subscription;
    public logoPath: string = '../../../../assets/images/logos/gatho-logo-gradient.svg';

    constructor(
        private _screenSizeService: ScreenSizeService,
        public router: Router
      ) {
        this.screenSizeServiceSub = _screenSizeService.screenSizeUpdated$.subscribe(
          (isMobile) => (this.isMobile = isMobile)
        );
    
      }

    ngOnInit(): void {
    }
}