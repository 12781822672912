import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from "@angular/forms";
import { ApiService } from "../../../services/api/api.service";
import { User } from "@shared/models/User";
import { UserService } from "../../../services/user/user.service";
import { SiteConfigDTO } from '@shared/models/SiteConfigDTO';
import {
  MatDialog,
} from '@angular/material/dialog';
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import { BrandingService } from '@gatho/services/branding/BrandingService';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  public logoPath: string = '../../../../assets/images/logos/gatho-logo-gradient.svg';
  public backgroundPath: string = '../../../../assets/images/opener-bg.jpg';
  public hide = true;
  public loading = false;
  public errorMessage?: string;
  public passwordNeedsReset?:string;
  public payload = {
    email: "",
    password: ""
  };
  public _settings: SiteConfigDTO = {} as SiteConfigDTO;

  renderLogo = false;

  constructor(private _route: Router,
              private _api: ApiService,
              private _userService: UserService,
              private _matDialog: MatDialog,
              private matDialog: MatDialog,
              private _branding: BrandingService
  ) {
    this.setBranding();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderLogo = true;
    }, 2000);
  }

  ngOnInit(): void {

  }

  setBranding() {
    this.logoPath = this._branding.getBrandingSetting('siteLogo') ?? this.logoPath;
    this.backgroundPath = this._branding.getBrandingSetting('loginBackground') ?? this.backgroundPath;
  }

  openGetPassword() {
    const dialogConfig = {
      maxWidth: '44rem',
      width: '44rem',
      panelClass: ['gathoDialog'],
    };
    this.matDialog.open(ForgotPasswordComponent, dialogConfig);
  }

  navigateToSignUp() {
    this._route.navigate(['sign-up']);
  }


  validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }


  checkEmailIsFromImportedUser = async (f: NgForm) => {
    this.passwordNeedsReset = '';
    //console.log(f);
    const email = f.value.email;
   if (email) {
     if (this.validateEmail(email)) {
       try {
         const response = await this._api.checkEmailForImportedUser(email);
         if (response.package && response.package.result) {
           const isImported = response.package.result;
           if (isImported === 'yes') {
             this.passwordNeedsReset = isImported;
           }
         }
       } catch (e) {

       }
     }
   }
  }

  onSubmit(f: NgForm): void{
    if(f.valid){
      this.loading = true;
      delete this.errorMessage;
      const email = f.value.email;
      const password = f.value.password;
      this._api.login(email, password).then((user: User) => {
        //console.log('login', user);
        this._userService.save(user);
        this.loading = false;

        const login_dest_url = localStorage.getItem('login_dest_url');
        if (login_dest_url) {
          localStorage.removeItem('login_dest_url');
          this._route.navigateByUrl(login_dest_url);
        }else{
          this._route.navigate(['']);
        }

      }).catch((e: Error) => {
        this.loading = false;
        this.errorMessage = "Your email or password is incorrect. Please try again.";
        this.payload.email = email;
        this.payload.password = password;
      });
    }
  }
}
