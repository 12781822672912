<h4 mat-dialog-title>Resend verification code</h4>
<mat-dialog-content class="mat-typography">
  <div class="resend_code">
    <form #f="ngForm" (ngSubmit)="onSubmit(f)" class="resendCodeForm">
      <div class="mat-error" *ngIf="errorMessage">{{ errorMessage }}</div>
      <div class="codeSentCheck" *ngIf="!codeHasSent">
        <div *ngIf="phoneOrEmail == 'phone'">
          <mat-form-field appearance="fill" class="has-bgLight">
            <mat-label>Phone</mat-label>
            <ngx-mat-intl-tel-input
              [preferredCountries]="['au', 'nz', 'us']"
              [enablePlaceholder]="true"
              placeholder="+61400000000"
              name="mobile"
              describedBy="phoneInput"
              [(ngModel)]="phoneOrEmailtoSend"
              [required]="true"
            >
            </ngx-mat-intl-tel-input>
          </mat-form-field>
        </div>

        <div *ngIf="phoneOrEmail == 'email'">
          <mat-form-field appearance="fill" class="has-bgLight">
            <mat-label>Email</mat-label>
            <input
              matInput
              type="email"
              name="email"
              autocomplete="email"
              placeholder="Email"
              [(ngModel)]="phoneOrEmailtoSend"
              required
              email
            />
          </mat-form-field>
        </div>

        <button
          type="submit"
          class="is-primary"
          mat-flat-button
          tabindex="5"
          #btnNext
        >
          Send code
        </button>
      </div>
      <div *ngIf="codeHasSent" class="codeSentSuccessMessage">
        Please check your {{ phoneOrEmail }} for your new verification code.
      </div>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-icon-button
    [mat-dialog-close]="true"
    aria-label="close icon"
    title="close"
  >
    <mat-icon>close</mat-icon>
  </button>
</mat-dialog-actions>
