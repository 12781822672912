import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService implements OnDestroy {

  private destroyed$ = new Subject<void>();
  isMobile!: boolean;
  isBpXSmall!: boolean;
  private screenSizeUpdatedSource = new BehaviorSubject<boolean>(this.isMobile);
  private screenSizeUpdatedSourceXSmall = new BehaviorSubject<boolean>(this.isBpXSmall);
  screenSizeUpdated$ = this.screenSizeUpdatedSource.asObservable();
  screenSizeUpdatedXSmall$ = this.screenSizeUpdatedSourceXSmall.asObservable();

  constructor(
    breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver
    .observe([
      // TODO: confirm required mobile breakpoint
      Breakpoints.Small,
      Breakpoints.XSmall,
    ])
    .pipe(takeUntil(this.destroyed$))
    .subscribe((result) => {
      const isMobile = result.matches;
      if (!this.isMobile) {
        this.isMobile = isMobile
      }
      this.screenSizeUpdatedSource.next(isMobile);
    });

    breakpointObserver
    .observe([
      Breakpoints.XSmall,
    ])
    .pipe(takeUntil(this.destroyed$))
    .subscribe((result) => {
      const isBpXSmall = result.matches;
      if (!this.isBpXSmall) {
        this.isBpXSmall = isBpXSmall
      }
      this.screenSizeUpdatedSourceXSmall.next(isBpXSmall);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
