<div class="template__signUp">
  <div class="bgSign">
    <div class="signupOuterBox gradientTheme hasScrollbarLight">
      <div class="signupOuterContent">
        <button
          type="button"
          (click)="navigateToLogin()"
          class="btnBack is-light"
          mat-icon-button
          aria-label="back arrow"
        >
          <mat-icon>keyboard_backspace</mat-icon>
        </button>
        <h6 class="is-light">Return to login</h6>
      </div>

      <div>
        <form #f="ngForm" (ngSubmit)="onSubmit(f)">
          <div class="singupInnerBox">
            <h4 class="is-light resetPassword__title text-center">
              Reset your password
            </h4>
          </div>
          <div class="signUpFormOuter">
            <div class="resetPassInner">
              <div *ngIf="showErrorMessage" class="restPassSucessMessage">
                Seems the verification password you entered is not valid or has
                expired. Try again or
                <button mat-stroked-button (click)="openGetPassword()">
                  Request new verification password
                </button>
              </div>
              <div *ngIf="showSentMessage" class="restPassSucessMessage">
                You’re all set! <br />
                Please login with your new password.
                <br />
                <br />
                <button mat-stroked-button (click)="navigateToLogin()">
                  Login
                </button>
              </div>

              <div *ngIf="!showSentMessage">
                <mat-form-field appearance="fill">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    type="email"
                    name="name"
                    autocomplete="email"
                    placeholder="Email"
                    [ngModel]="payload.name"
                    #name="ngModel"
                    email
                    required
                    tabindex="1"
                  />
                  <mat-error
                    *ngIf="name.value === '' && name.touched"
                    class="mat-error is-white"
                    >Please enter your email address</mat-error
                  >
                </mat-form-field>

                <mat-form-field appearance="fill">
                  <mat-label>Verification password</mat-label>
                  <input
                    matInput
                    type="text"
                    name="temp_pass"
                    placeholder="Verification password from email"
                    [ngModel]="payload.temp_pass"
                    #temp_pass="ngModel"
                    required
                    tabindex="2"
                  />
                  <mat-error
                    *ngIf="temp_pass.value === '' && temp_pass.touched"
                    class="mat-error is-white"
                    >Please enter your verification password received from
                    email.</mat-error
                  >
                </mat-form-field>

                <mat-form-field appearance="fill">
                  <mat-label>New password</mat-label>
                  <input
                    matInput
                    [type]="hide ? 'password' : 'text'"
                    name="new_pass"
                    placeholder="New password"
                    [ngModel]="payload.new_pass"
                    #new_pass="ngModel"
                    required
                    tabindex="3"
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="hide = !hide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide"
                    type="button"
                  >
                    <mat-icon>{{
                      hide ? "visibility_off" : "visibility"
                    }}</mat-icon>
                  </button>

                  <mat-error
                    *ngIf="new_pass.value === '' && new_pass.touched"
                    class="mat-error is-white"
                    >Please enter your new password.</mat-error
                  >
                </mat-form-field>

                <button mat-stroked-button type="submit" [disabled]="!f.valid">
                  RESET PASSWORD
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
